import {configureStore } from '@reduxjs/toolkit'
import authSlice from './redux/authSlice'
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  auth: authSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer
})

export default store