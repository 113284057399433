import React, { createRef, LegacyRef, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../configureStore'
import { api, getJwtToken } from '../utils/api'

const Home = () => {

    const textArea = createRef<HTMLTextAreaElement>()
    const history = useHistory()
    const [List, setList] = useState<string[]>([])
    const [SmsBody, setSmsBody] = useState("")
    const [From, setFrom] = useState("Menual")
    const auth = useSelector((state:RootState) => state.auth)

    useEffect(() => {

        let token = getJwtToken()

        if (!token) {
            history.replace("/login")
        }
    }, [])

    const AddToList = () => {
        console.log("AddToList", textArea.current)
        if (textArea.current) {
            //@ts-ignore
            let numbers = textArea.current!.value.split("\n")
            numbers.forEach((phone, index) => {
                numbers[index] = phone.replace(/[^\d\+]/g,"");
            })
            setList(numbers)
            textArea.current!.value = ""
        }


    }

    const SendSMS = () => {
        console.log("SendSMS")
        api.post("/ws/Marketing.asmx/SendManualCampaign", api.serializeJSON!({
            Token : auth.user.Token,
            Numbers : List.join(","),
            From,
            Body : SmsBody
        }))
        .then(({data}) => {
            console.log("SMS Sent", data)
            alert("SMS Sent")
        })
        .catch((e) => {
            console.warn("could not send sms")
            alert("Could not sent SMS")
        })
        //api.post()
    }

    const ClearList = () => {
        setList([])
    }

    return (
        <div className="container">
            <h1>SMS Campaing</h1>

            <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">Paste phone numbers</label>
                <textarea ref={textArea} className="form-control" id="exampleFormControlTextarea1" rows={3}></textarea>
                <button onClick={AddToList}>Add To List</button>
            </div>

            <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">From Name</label>
                    <input type="text" value={From}  onChange={(e) => setFrom(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
            <div className="mb-3">
                <label htmlFor="messageBody" className="form-label">SMS Body:</label>
                <textarea value={SmsBody} onChange={(e) => {
                    if (e.target.value.length <= 300)
                        setSmsBody(e.target.value)
                }} className="form-control" id="messageBody" rows={3}></textarea>
                <span>{SmsBody.length}/300</span>
            </div>

            <button className="btn btn-success" onClick={SendSMS}>Send SMS</button>
            <br />
            <br />

            <button className="btn btn-danger" onClick={ClearList} >Clear List List</button>

            <ul>
                {List.map((phone, key) => {
                    return <li key={key}>{phone}</li>
                })}
            </ul>
        </div>
    )
}

export default Home
