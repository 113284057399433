import Axios, { AxiosInstance } from 'axios'
declare interface CustomAxiosInstance extends AxiosInstance {
    getMedia?: (media: { url: string }) => string,
    serializeJSON? : (data : any) => string
}
const api:CustomAxiosInstance = Axios.create({
    baseURL : 'https://menual.azurewebsites.net'
})

api.serializeJSON = (data) => {
  console.log("serializeJSON", data != null)
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
      );
    })
    .join("&");
};

// menual.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     if (config.method == "POST") {
//         config.data = menual.serializeJSON(config.data)
//     }

//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

export const storeJwtToken = (token:string) => {
  localStorage.setItem('@jwtToken2', token)
  
}

export const clearJwtToken = () => {
  
    localStorage.setItem('@jwtToken2', "")
    delete api.defaults.headers["Authorization"]
  
}

export const getJwtToken = () => {
  let value;
  try {
    value = localStorage.getItem('@jwtToken2')
    if(value !== null) {
      // value previously stored
      return JSON.parse(value)
    }
  } catch(e) {
    // error reading value
    console.warn("getJwtToken", value)
    return null
  }
}

//menual.getMedia = 
api.getMedia = (media: { url: string }) => {

  if (!media)
    return ""
  const url = api.defaults.baseURL + media.url
  //console.log("getMedia url = ", url)
  return url;
}


export {
    api
}