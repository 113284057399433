import { createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api, storeJwtToken, getJwtToken } from '../utils/api';


export const login = createAsyncThunk(
    'auth/login',
    async (params : any) => {
        console.log("authSlice login baseURL", api.defaults.baseURL + '/ws/User.asmx/LoginFromWeb');
        const response = await api.get(
            '/ws/User.asmx/LoginFromWeb?Email=' + params.email + '&Password=' + params.password
        );
        console.log('login auth response', response.data);
        if(response.data.error){
            throw response.data.error
        }
        return response.data;
    }
);



export type BusinessEnumType = "restaurant" | "shop"

type InitialType = {
    authenticating: boolean,
    loginError: string,
    user: {
        ID: string,
        Token: string,
        Name: string,
        SiteAdmin: boolean,
    }
}

const initialState: InitialType = {
    user: getJwtToken() || {
        ID: "",
        Token: "",
        Name: "",
        SiteAdmin: false,
    },
    authenticating: false,
    loginError: ""
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        //Fecth device info
        builder.addCase(login.pending, (state) => {
            state.authenticating = true;
            state.loginError = ""
        });
        builder.addCase(login.fulfilled, (state, action) => {

            storeJwtToken(JSON.stringify(action.payload))
            state.user = action.payload;
            
            
        });
        builder.addCase(login.rejected, (state, action) => {
            state.authenticating = false;
            console.log("invalid login", action.error.message)
            state.loginError = "Invalid Login"
        });
        
    }
})

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export const validateName = (email: string) => {
    const re = /^[a-z\u0590-\u05fe]+$/i
    return re.test(String(email).toLowerCase());
}

const {
    
} = authSlice.actions

export {
    
}

export default authSlice