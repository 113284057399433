import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../configureStore'
import { login } from '../redux/authSlice'

const Login = () => {
    const dispatch = useDispatch()
    const  history = useHistory()
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const auth = useSelector((state:RootState) => state.auth)
    
    const onLogin = () => {
        dispatch(login({
            email : Email,
            password : Password
        }))
    }

    useEffect(() => {
        
        if(auth.user && auth.user.Token){
            history.replace("/")
        }
    }, [auth.user])


    return (
        <div className="container">
            <div className="col-12" style={{
                marginTop : 50,
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                flexDirection : 'column',
                border : '1px solid #333',
                padding : 20
            }}>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                    <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
                <div className="mb-3">
                    <label htmlFor="passwordInput" className="form-label">Password</label>
                    <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" id="passwordInput" placeholder="Password" />
                </div>

                <button className="btn btn-primary" onClick={onLogin}>Login</button>
            </div>

            {auth.loginError && <span className="alert alert-danger">{auth.loginError}</span>}
        </div>
    )
}

export default Login
